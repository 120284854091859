import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import HomeScreen from './components/HomeScreen'
import AppMain from './components/AppMain'
import NotFoundScreen from './components/NotFoundScreen'
import * as serviceWorker from './serviceWorker';
import 'fontsource-roboto';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Amplify from 'aws-amplify'
import { Authenticator, View, Image, useTheme } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import './index.css'
require('dotenv').config()
Amplify.configure(awsExports);

const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
      paper: '#ffffff'
    },
    primary: {
      light: '#a647ea',
      main: '#9606EA',
      dark: '#0E0B42',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#cccccc',
      contrastText: '#212121',
    },
    text: {
      primary: "#212121",
      secondary: "#3d0086"
    }
  },
});

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Okay investments logo"
          src="https://okaybucket.s3.us-east-2.amazonaws.com/BCArtboard+1+copy+4%402x.png"
          height="30%"
          width="30%"
        />
      </View>
    );
  },
}

function App () {

  return ( 
     <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/" exact component={HomeScreen} />
            <Authenticator components={components} hideSignUp={true}>
              <Route path="/app" component={AppMain} />
            </Authenticator>
            <Route path="/404" component={NotFoundScreen} />
            <Route path="*">
              <Redirect to="/404" /> 
            </ Route>
          </Switch>
      </ThemeProvider> 
  )
}

ReactDOM.render(
  <Authenticator.Provider>
    <Router>
      <App />
    </Router>
  </Authenticator.Provider>,
    document.getElementById('root')
);


serviceWorker.unregister();
