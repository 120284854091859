import { Link } from "react-router-dom";
import { Button } from '@material-ui/core'
import { useAuthenticator } from '@aws-amplify/ui-react';

const LoginButton = () => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  return(
    <div>
      <Button color="secondary" component={Link} to={'/app'}> {authStatus === 'authenticated' ? 'Manage trades' : 'Log in'} </Button>
    </div>
  )
};

export default LoginButton;