import React, { useState, useRef }  from "react";
import clsx from 'clsx';
import { Typography, AppBar, IconButton, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, CssBaseline, Snackbar, Box, Grid } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import DonutLargeOutlinedIcon from '@material-ui/icons/DonutLargeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import DvrOutlinedIcon from '@material-ui/icons/DvrOutlined';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DashboardMenu from '../enums'
import TradingLogView from "./TradingLogView";
import RiskCalculatorView from "./RiskCalculatorView";
import DashboardView from "./DashboardView";
import RiskMonitoringView from "./RiskMonitoringView";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { AppContext } from '../AppContext';
//@ts-ignore
import { useAuthenticator } from '@aws-amplify/ui-react';
import ForecastView from "./ForecastView";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    }, 
    rootAlert: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

interface AppMainState {
  editTrade: (trade: Trade) => void,
  deleteTrade: (trade: Trade) => void,
  showError: (msg: string | undefined) => void
}
// @ts-ignore
function AppMain() {
    const classes = useStyles();
    const tradingLogViewRef = useRef();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(DashboardMenu.dashboard);
    const [openSnack, setOpenSnack] = useState(false);
    const [tradInFocus, setTradeInFocus] = useState<Trade | undefined>()
    const { user, signOut } = useAuthenticator((context) => [context.user]);

    const showError = (msg: string | undefined) => {
      setOpenSnack(true)
    }

    const editTrade = (trade: Trade | undefined) => {
      setTradeInFocus(trade)
      //@ts-ignore
      tradingLogViewRef.current.editTrade()
    }

    const deleteTrade = (trade: Trade | undefined) => {
      setTradeInFocus(trade)
      //@ts-ignore
      tradingLogViewRef.current.deleteTrade()
    }

    const [state] = useState<AppMainState>(
      { 
          showError: showError,
          editTrade: editTrade,
          deleteTrade: deleteTrade
      }
    )

    const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
          return
      }
      setOpenSnack(false)
    };
 
    const handleMenuClick = (item: DashboardMenu) => {
        switch (item) {
            case DashboardMenu.dashboard:
                setActiveMenu(DashboardMenu.dashboard)
            break
            case DashboardMenu.tradingLog:
                setActiveMenu(DashboardMenu.tradingLog)
            break
            case DashboardMenu.riskCalculator:
                setActiveMenu(DashboardMenu.riskCalculator)
            break
            case DashboardMenu.settings:
                setActiveMenu(DashboardMenu.settings)
            break
            case DashboardMenu.riskMonitoring:
                setActiveMenu(DashboardMenu.riskMonitoring)
            break
            case DashboardMenu.forecast:
                setActiveMenu(DashboardMenu.forecast)
            break
            case DashboardMenu.logout:
                signOut()
            break
        }
    }
    const selectedColor = (menu: DashboardMenu) => {
      if (menu === activeMenu) { return theme.palette.primary.dark}
    }
    const handleDrawerOpen = () => {
      setOpen(true)
    }
  
    const handleDrawerClose = () => {
      setOpen(false)
    }

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
            })}
         >
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                [classes.hide]: open,
                })}
            >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
          <Typography variant="h6" noWrap>
            {user.attributes?.given_name}'s Okay Investments  
          </Typography>
          </Grid>                   
        </Toolbar>
        
       </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
                })}
                classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
               <List>
                    <ListItem button onClick={() => handleMenuClick(DashboardMenu.dashboard)}>
                     <ListItemIcon><DonutLargeOutlinedIcon style={{color:selectedColor(DashboardMenu.dashboard)}}/></ListItemIcon>
                     <ListItemText style={{color:selectedColor(DashboardMenu.dashboard)}} primary='Dashboard' />
                    </ListItem>
               </List>
               <List>
                    <ListItem button onClick={() => handleMenuClick(DashboardMenu.tradingLog)} >
                     <ListItemIcon><DvrOutlinedIcon style={{color:selectedColor(DashboardMenu.tradingLog)}}/></ListItemIcon>
                     <ListItemText style={{color:selectedColor(DashboardMenu.tradingLog)}} primary='Trading log' />
                    </ListItem>
               </List>
               <List>
                    <ListItem button onClick={() => handleMenuClick(DashboardMenu.riskCalculator)} >
                     <ListItemIcon><TuneOutlinedIcon style={{color:selectedColor(DashboardMenu.riskCalculator)}}/></ListItemIcon>
                     <ListItemText style={{color:selectedColor(DashboardMenu.riskCalculator)}} primary='Risk calculator' />
                    </ListItem>
               </List>
               <List>
                    <ListItem button onClick={() => handleMenuClick(DashboardMenu.riskMonitoring)} >
                     <ListItemIcon><LocalLibraryOutlinedIcon style={{color:selectedColor(DashboardMenu.riskMonitoring)}}/></ListItemIcon>
                     <ListItemText style={{color:selectedColor(DashboardMenu.riskMonitoring)}} primary='Risk monitoring' />
                    </ListItem>
               </List>
               <List>
                    <ListItem button onClick={() => handleMenuClick(DashboardMenu.forecast)} >
                     <ListItemIcon><ShowChartIcon style={{color:selectedColor(DashboardMenu.forecast)}}/></ListItemIcon>
                     <ListItemText style={{color:selectedColor(DashboardMenu.forecast)}} primary='Forecast' />
                    </ListItem>
               </List>
               <Divider />
               <List>
                    <ListItem button onClick={() => handleMenuClick(DashboardMenu.settings)} disabled>
                     <ListItemIcon><SettingsOutlinedIcon style={{color:selectedColor(DashboardMenu.settings)}}/></ListItemIcon>
                     <ListItemText style={{color:selectedColor(DashboardMenu.settings)}} primary='Settings' />
                   </ListItem>
               </List>
               <List>
                    <ListItem button onClick={() => handleMenuClick(DashboardMenu.logout)}>
                     <ListItemIcon><ExitToAppOutlinedIcon style={{color:selectedColor(DashboardMenu.logout)}}/></ListItemIcon>
                     <ListItemText style={{color:selectedColor(DashboardMenu.logout)}} primary='Log out' />
                   </ListItem>
               </List>
           </Drawer>
           <AppContext.Provider value={state}>
             <main className={classes.content}>
                <div className={classes.toolbar} />
                <DashboardView hidden={activeMenu !== DashboardMenu.dashboard} />
                <TradingLogView ref={tradingLogViewRef} tradInFocus={tradInFocus} hidden={activeMenu !== DashboardMenu.tradingLog} />
                <RiskCalculatorView hidden={activeMenu !== DashboardMenu.riskCalculator} />                
                <RiskMonitoringView hidden={activeMenu !== DashboardMenu.riskMonitoring} />
                <ForecastView symbol={'SPY'} hidden={activeMenu !== DashboardMenu.forecast} />
                <Typography paragraph hidden={activeMenu !== DashboardMenu.settings}>
                    This is the settings
                </Typography>
                <Box className={classes.rootAlert}> 
                  <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                  <Alert onClose={handleCloseSnack} severity="error">
                      Looks like that didn't go through! Try again later.
                  </Alert>
                  </Snackbar>
                </Box>
             </main>
           </AppContext.Provider>
        </ div>
        
    ) 
}

export default AppMain;