import React, { useState, useEffect }  from "react";
import { Typography, Paper, InputAdornment, TextField, Slider, Divider } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import erf from '@stdlib/math-base-special-erf';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '25ch',
        },
      },
    alignRight: {
        float:'right',
    },
    withPadding: {
        margin: theme.spacing(1)
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #fff',
      boxShadow: theme.shadows[2],
      padding: theme.spacing(2, 4, 6),
      outline: 'none'
    },
  }));

interface RiskCalculatorViewProps {
    hidden: boolean
}

function RiskCalculatorView(props: RiskCalculatorViewProps) {
    const classes = useStyles()
    const theme = useTheme()
    const [strikePrice, setStrikePrice] = useState<number>(300)
    const [daysToExpire, setDaysToExpire] = useState<number>(25)
    const [impliedVolatility, setImpliedVolatility] = useState<number>()
    const [underlyingPrice, setUnderlyingPrice] = useState<number>()
    const [bufferFromPrice, setBufferFromPrice] = useState<number>(0)
    const [bufferFromFirstSigma, setBufferFromFirstSigma] = useState<number>(0)

    const [firstSigma, setFirstSigma] = useState<number>(0)
    const [midSigma, setMidSigma] = useState<number>(0)
    const [secondSigma, setSecondSigma] = useState<number>(0)
    const [otmProbability, setOtmProbability] = useState<number>(0)

    const handleStrikeChange = (event: any, newValue: number | number[]) => {
        setStrikePrice(newValue as number)
    }

    const handleDayChange = (event: any, newValue: number | number[]) => {
        setDaysToExpire(newValue as number)
    }

    const handleTradeDataChange = (event: any) => {
        const name = event.target.name
        const value = event.target.value

        switch (name) {
            case 'impliedVolatility':
                setImpliedVolatility(value)
            break;
            case 'underlyingPrice':
                setUnderlyingPrice(value)
            break;
        }
    }

    

    useEffect(() => {
        function updateResults() {
            if (underlyingPrice && impliedVolatility) {
                const sigma = underlyingPrice * (impliedVolatility / 100) * Math.sqrt(daysToExpire/260)
                const firstStdDev = underlyingPrice - sigma
                const secondStdDev = underlyingPrice - (2 * sigma)
                const midStdDev= (firstStdDev + secondStdDev) / 2
            
                const z = (strikePrice - underlyingPrice) / sigma
                const percentile = (0.5 * (1+ erf(z / Math.sqrt(2)))) * 100
                const OTMProbability = (100 - percentile)

                const bufferFromPrice = ((underlyingPrice - strikePrice) / underlyingPrice) * 100
                const bufferFromFirstStdDev = ((firstStdDev - strikePrice) / firstStdDev) * 100

                setFirstSigma(firstStdDev)
                setMidSigma(midStdDev)
                setSecondSigma(secondStdDev)
                setOtmProbability(OTMProbability)
                setBufferFromPrice(bufferFromPrice)
                setBufferFromFirstSigma(bufferFromFirstStdDev)
            }
        }

        updateResults()
    })


    if (props.hidden === true) {
        return <div />
    } 
    return(
        <div>
            <Typography variant='h4' style={{color:theme.palette.primary.dark}}>Risk calculator</Typography>
            <br></br>
            <Paper className={classes.paper}>
            <form className={classes.root} noValidate autoComplete="off">
                <br></br>
                <div>
                    <TextField value={firstSigma?.toFixed(2)} type='number' name="σ" label="σ" variant="outlined" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true,}}/>
                    <TextField value={midSigma?.toFixed(2)} type='number' name="1.5σ" label="1.5σ" variant="outlined" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true,}}/>
                    <TextField value={secondSigma?.toFixed(2)} type='number' name="2σ" label="2σ" variant="outlined" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true,}}/>
                    <TextField value={otmProbability?.toFixed(2)} type='number' name="OTM Probability" label="OTM Probability" variant="outlined" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, readOnly: true,}}/>
                </div> 
                <div>
                    <TextField value={bufferFromPrice?.toFixed(2)} type='number' name="Price buffer" label="Underlying price buffer" variant="outlined" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, readOnly: true,}}/>
                    <TextField value={bufferFromFirstSigma?.toFixed(2)} type='number' name="σ buffer" label="σ buffer" variant="outlined" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, readOnly: true,}}/>
                </div>
                <br></br>
                <br></br>  
                <Divider />
                <br></br> 
                <br></br>  
                <Typography variant='h6' style={{color:theme.palette.primary.dark}}>Trade Details</Typography>
                <div>
                    <TextField value={impliedVolatility || ''} size='small' type='number' required name="impliedVolatility" label="Implied volatility" variant="outlined" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} onChange={ (e) => handleTradeDataChange(e)}/>
                    <TextField value={underlyingPrice || ''} size='small' type='number' required name="underlyingPrice" label="Underlying price" variant="outlined" helperText="Current market price" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,}} onChange={ (e) => handleTradeDataChange(e)}/>
                </div>
                <div>
                    <br></br>
                    <Typography variant='h6' style={{color:theme.palette.primary.dark}}>Days-to-expire</Typography>
                    <br></br>
                    <br></br>
                    <Slider 
                        value={daysToExpire}
                        style={{color:theme.palette.primary.dark}} 
                        onChange={handleDayChange}
                        valueLabelDisplay="on"
                        min={1}
                        max={60}
                        step={1} />
                </div>  
                <div>
                    <br></br>
                    <Typography variant='h6' style={{color:theme.palette.primary.dark}}>Strike price</Typography>
                    <br></br>
                    <br></br>
                    <Slider 
                        value={strikePrice}
                        style={{color:theme.palette.primary.dark}} 
                        onChange={handleStrikeChange}
                        valueLabelDisplay="on" 
                        min={50}
                        max={650}
                        step={1} />
                </div>        
            </form>
            <br></br>         
            </Paper>            
        </div>
    )
}

export default RiskCalculatorView