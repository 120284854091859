import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  SvgIcon
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: (props: DashboardItemProps) => ( {
    backgroundColor: props.iconBgColor,
    height: 40,
    width: 40
  })
}));

interface DashboardItemProps {
    value: string,
    title: string,
    icon: typeof SvgIcon
    iconBgColor: string | undefined
}

function DashboardItemView(props: DashboardItemProps) {
  const classes = useStyles(props)

  return (
    <Card>
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {props.title}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {props.value}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
                <props.icon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}


 export default DashboardItemView
