import React, { useEffect, useContext, useState }  from "react";
import { Typography, Grid, Box} from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import RiskMonitoringItemView from "./RiskMonitoringItemView";
import LoadingScreen from "./LoadingScreen";
import { AppContext } from '../AppContext';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

interface RiskMonitoringViewProps {
    hidden: boolean
}

function RiskMonitoringView(props: RiskMonitoringViewProps) {
    const theme = useTheme()
    const appContext = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(false)
    const [trades, setTrades] = useState([]);
    const [didReturnEmpty, setDidReturnEmpty] = useState(false);
    const { user, authStatus } = useAuthenticator((context) => [context.user])
    const getAccessJwtToken = async () => {
        // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        return token
    };

    useEffect(() => {
        async function getTrades() {
            if (authStatus === 'authenticated') {
              setIsLoading(true)
              try {
                const result = await fetch(`${process.env.REACT_APP_API_DOMAIN}/gettrades?userId=${user.attributes?.email}`,
                {
                    headers: {
                        'Authorization': `${await getAccessJwtToken()}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await result.clone().json();
                console.log('Result:', data);
                setDidReturnEmpty(data.length === 0)
                setTrades(data)
                setIsLoading(false)
              } catch (error) {
                setIsLoading(false)  
                console.error('Error', error);
                appContext.showError('')
               }  
            }      
        }
         
        if (trades.length === 0 && !didReturnEmpty) {
            getTrades()
        } 

    }, [appContext, user.attributes?.email, authStatus, trades.length, didReturnEmpty])


    if (props.hidden === true) {
        return <div />
    }
    
    if (isLoading) {
        return <LoadingScreen />
    }

    return(
        <div>
        <Typography variant='h4' style={{color:theme.palette.primary.dark}}>Risk monitoring</Typography>
            <br></br>
            <Box display={ !didReturnEmpty ? 'none' : 'inline'}>
                  <br></br>
                  <Typography variant='subtitle1' color='textPrimary'>No trades found</Typography>
            </Box>   
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
            >
                {trades.filter((trade:Trade) => (trade.status === 'OPEN' && trade.currentOTMProb !== null && trade.currentOTMProb !== undefined)).sort((a:Trade, b:Trade) => a.currentOTMProb! - b.currentOTMProb!).map((trade: Trade, _) => (
                    <Grid key={trade.id} item xs>
                        <RiskMonitoringItemView trade={trade}/>
                    </Grid>
                ))}  
            </Grid>
            <br></br>         
        </div>
    )
}

export default RiskMonitoringView