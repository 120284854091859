enum DashboardMenu {
    dashboard = 'dashboard',
    tradingLog = 'tradingLog',
    riskMonitoring = 'riskMonitoring',
    riskCalculator = 'riskCalculator',
    forecast = 'forecast',
    settings = 'settings',
    logout = 'logout'
}

export default DashboardMenu