import React, { useState, useEffect, useContext, useImperativeHandle, forwardRef }  from "react";
import { Typography, Table, TableRow, TableCell, TableHead, TableBody, TableContainer, Box, IconButton, Collapse, Paper, Tooltip, Fab, Modal, Backdrop, Fade, Button} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LoadingScreen from "./LoadingScreen";
import moment from 'moment';
import AddTradeView from "./AddTradeView";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { AppContext } from '../AppContext';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

moment.suppressDeprecationWarnings = true

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    fab: {
      margin: 0,
      top: 'auto',
      right: theme.spacing(3),
      bottom: theme.spacing(3),
      left: 'auto',
      position: 'fixed',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #fff',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: 'none'
    },
    withPadding: {
      margin: theme.spacing(1)
    },
}));

interface RowProps {
    trade: Trade,
    index: number
}

function Row(props: RowProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const appContext = useContext(AppContext);
  const theme = useTheme()

  function formatDate(date: Date) {
      if (moment(date).isValid()) {
          return moment(date).utc().format('MMM DD YYYY')
      } else {
          return '-'
      }
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} style={(props.index%2) ? ({backgroundColor: theme.palette.common.white}) : ({backgroundColor: theme.palette.action.hover})} key='topLevel'>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <IconButton onClick={ () => appContext.editTrade(props.trade) }>
              <EditIcon />
          </IconButton>
          <IconButton onClick={ () => appContext.deleteTrade(props.trade)}>
              <DeleteIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {props.trade.symbol}
        </TableCell>
        <TableCell align="right">{(props.trade.price) ? `$${(Number(props.trade.price).toFixed(2))}` : '-'}</TableCell>
        <TableCell align="right">{(props.trade.expirationDate) ? (formatDate(props.trade.expirationDate)) : '-'}</TableCell>
        <TableCell align="right">{(props.trade.strikePriceA) ? `$${(Number(props.trade.strikePriceA).toFixed(2))}` : '-'}</TableCell>
        <TableCell align="right">{(props.trade.transactionDate) ? (formatDate(props.trade.transactionDate)) : '-'}</TableCell>
        <TableCell align="right">{props.trade.status}</TableCell>
      </TableRow>
      <TableRow key='detailsHeader' style={(props.index%2) ? ({backgroundColor: theme.palette.common.white}) : ({backgroundColor: theme.palette.action.hover})}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Transaction details
              </Typography>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow key='transactionDetailsHeader'>
                    <TableCell><Typography variant='subtitle1' color='textSecondary'>σ</Typography></TableCell>
                    <TableCell><Typography variant='subtitle1' color='textSecondary'>1.5σ</Typography></TableCell>
                    <TableCell><Typography variant='subtitle1' color='textSecondary'>2σ</Typography></TableCell>
                    <TableCell>
                      <Tooltip title='Buffer from underlying price'>
                          <Typography variant='subtitle1' color='textSecondary'>BFP</Typography> 
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Buffer from first sigma'>
                          <Typography variant='subtitle1' color='textSecondary'>BFσ</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Breakeven point'>
                          <Typography variant='subtitle1' color='textSecondary'>BEP</Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key='transactionDetailsData'>
                      <TableCell>{(props.trade.firstStddev) ? `$${(Number(props.trade.firstStddev).toFixed(2))}` : '-'}</TableCell>
                      <TableCell>{(props.trade.midStddev) ? `$${(Number(props.trade.midStddev).toFixed(2))}` : '-'}</TableCell>
                      <TableCell>{(props.trade.secondStddev) ? `$${(Number(props.trade.secondStddev).toFixed(2))}` : '-'}</TableCell>                        
                      <TableCell>{(props.trade.bufferFromPrice) ? (`${Number(props.trade.bufferFromPrice*100).toFixed(2)}%`) : '-'}</TableCell>
                      <TableCell>{(props.trade.bufferFromFirstStddev) ? (`${Number(props.trade.bufferFromFirstStddev*100).toFixed(2)}%`) : '-'}</TableCell>
                      <TableCell>{(props.trade.breakevenPoint) ? `$${(Number(props.trade.breakevenPoint).toFixed(2))}` : '-'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow key='transactionDetailsHeader'>
                  <TableCell>
                      <Tooltip title='Entry or opening price'>
                          <Typography variant='subtitle1' color='textSecondary'>Underlying price</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Hedge option strike price aka "lower" strike price'>
                          <Typography variant='subtitle1' color='textSecondary'>Strike B</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Trade exposure. Maximum potential loss or $ at risk.'>
                          <Typography variant='subtitle1' color='textSecondary'>Expo</Typography>
                      </Tooltip>
                    </TableCell>                      
                    <TableCell><Typography variant='subtitle1' color='textSecondary'>% return</Typography></TableCell>
                    <TableCell><Typography variant='subtitle1' color='textSecondary'>P/L</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key='transactionDetailsData'>
                      <TableCell>{(props.trade.underlyingPrice) ? `$${(Number(props.trade.underlyingPrice).toFixed(2))}` : '-'}</TableCell>
                      <TableCell>{(props.trade.strikePriceB) ? `$${(Number(props.trade.strikePriceB).toFixed(2))}` : '-'}</TableCell>
                      <TableCell>{(props.trade.exposure) ? `$${(Number(props.trade.exposure).toFixed(2))}` : '-'}</TableCell>
                      <TableCell>{(props.trade.pctReturn) ? (`${Number(props.trade.pctReturn*100).toFixed(2)}%`) : '-'}</TableCell>
                      <TableCell>{(props.trade.profitLoss) ? `$${(Number(props.trade.profitLoss).toFixed(2))}` : '-'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box marginTop={4}>
                  <Typography variant="h6" gutterBottom component="div">
                  Closing information
                  </Typography>
                  <Table size="small" aria-label="details" >
                  <TableHead>
                      <TableRow key='closingDetailsHeader'>
                      <TableCell><Typography variant='subtitle1' color='textSecondary'>Date</Typography></TableCell>
                      <TableCell><Typography variant='subtitle1' color='textSecondary'>Price</Typography></TableCell>
                      <TableCell>
                          <Tooltip title='% distance between the closing and strike price'>
                              <Typography variant='subtitle1' color='textSecondary'>% from strike</Typography>
                          </Tooltip>
                      </TableCell>
                      <TableCell>
                          <Tooltip title='% distance between the underlying price during the point of entry and closing'>
                              <Typography variant='subtitle1' color='textSecondary'>% from entry</Typography>
                          </Tooltip>
                      </TableCell>
                      <TableCell><Typography variant='subtitle1' color='textSecondary'>Within σ</Typography></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow key='closingDetailsData'>
                          <TableCell>{(props.trade.closingDate) ? (formatDate(props.trade.closingDate)) : '-'}</TableCell>
                          <TableCell>{(props.trade.underlyingClosingPrice) ? `$${(Number(props.trade.underlyingClosingPrice).toFixed(2))}` : '-'}</TableCell>
                          <TableCell>{(props.trade.closingPctFromStrike) ? (`${Number(props.trade.closingPctFromStrike*100).toFixed(2)}%`) : '-'}</TableCell>
                          <TableCell>{(props.trade.closingPctFromEntry) ? (`${Number(props.trade.closingPctFromEntry*100).toFixed(2)}%`) : '-'}</TableCell>
                          <TableCell>{props.trade.isWithinFirstStddev ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                  </TableBody>
                  </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface CollapsibleTableProps {
    trades: Trade[]
}

function CollapsibleTable(props: CollapsibleTableProps) {
  const theme = useTheme()
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell><Typography variant='h6' color='textSecondary'>Underlying</Typography></TableCell>
            <TableCell align="right"><Typography variant='h6' style={{color:theme.palette.primary.dark}}>Price</Typography></TableCell>
            <TableCell align="right"><Typography variant='h6' style={{color:theme.palette.primary.dark}}>Expiration date</Typography></TableCell>
            <TableCell align="right">
              <Tooltip title='Trade strike price aka "higher" strike price'>
                  <Typography variant='h6' color='textSecondary'>Strike A</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="right"><Typography variant='h6' style={{color:theme.palette.primary.dark}}>Transaction date</Typography></TableCell>
            <TableCell align="right"><Typography variant='h6' style={{color:theme.palette.primary.dark}}>Status</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.trades.map((trade, index) => (
            <Row index={index} key={trade.id} trade={trade} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface TradingLogViewProps {
    hidden: boolean,
    tradInFocus: Trade | undefined
}

const TradingLogView = forwardRef((props: TradingLogViewProps, ref: any) => {
    const classes = useStyles();
    const [trades, setTrades] = useState([]);
    const [shouldOpenModel, setShouldOpenModal] = useState(false);
    const [shouldOpenDeleteConfirmModal, setShouldOpenDeleteConfirmModal] = useState(false);
    const [didReturnEmpty, setDidReturnEmpty] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const appContext = useContext(AppContext);
    const theme = useTheme()
    const { user, authStatus } = useAuthenticator((context) => [context.user])
    const getAccessJwtToken = async () => {
      // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      return token
    };

    useImperativeHandle(ref, () => ({
      editTrade(){
        setIsEditMode(true)
        handleOpenAddEditTradeView()
      },
      deleteTrade(){
        setIsEditMode(false)
        handleOpenDeleteConfirmView()
      }
    }));

    const handleOpenAddEditTradeView = () => {
      setShouldOpenModal(true)
    }
  
    const handleCloseAddEditTradeView = () => {
      setIsEditMode(false)
      setShouldOpenModal(false)
    }

    const handleOpenDeleteConfirmView= () => {
      setShouldOpenDeleteConfirmModal(true)
    }

    const handleCloseDeleteConfirmView = () => {
      setShouldOpenDeleteConfirmModal(false)
    }

    async function getTrades() {
           
        if (authStatus === 'authenticated') {
          try {
            const result = await fetch(`${process.env.REACT_APP_API_DOMAIN}/gettrades?userId=${user.attributes?.email}`,
                {
                    headers: {
                        'Authorization': `${await getAccessJwtToken()}`,
                        'Content-Type': 'application/json'
                    }
                });
            const data = await result.clone().json();
            console.log('Result:', data);
            setDidReturnEmpty(data.length === 0)
            //@ts-ignore
            data.sort(function(a,b){
              //@ts-ignore
              return new Date(b.expirationDate!) - new Date(a.expirationDate!)
            })
            setTrades(data)
          } catch (error) {
            console.error('Error', error);
            appContext.showError('')
           }  
        }      
    }

    async function deleteTrade() {
      handleCloseDeleteConfirmView()
      if (authStatus === 'authenticated') {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_DOMAIN}/deletetrade?tradeId=${props.tradInFocus?.id}&userId=${user.attributes?.email}`,
            {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                  'Authorization': `${await getAccessJwtToken()}`,
                  'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
  
            const resp = result.clone()  
            if (resp.status === 200) {
              getTrades()
            } else {
              appContext.showError('')
            }  
          } catch (error) {
  
            console.error('Error', error);
            appContext.showError('')
        }
      } 
    }

    useEffect(() => {
        if (trades.length === 0 && !didReturnEmpty) {
          getTrades()
        }
    })

    if (props.hidden === true) {
        return <div />
    } else {
        if (trades.length === 0 && !didReturnEmpty) {
          return <LoadingScreen />
        }
        return (             
            <div>
                <Typography variant='h4' style={{color:theme.palette.primary.dark}}>Trading log</Typography>
                <Box display={ !didReturnEmpty ? 'none' : 'inline'}>
                  <br></br>
                  <Typography variant='subtitle1' color='textPrimary'>No trades found</Typography>
                </Box>                
                <Fab size="medium" color='primary' aria-label="add" className={classes.fab} onClick={handleOpenAddEditTradeView}>
                    <AddIcon />
                </Fab>
                <Box display={ didReturnEmpty ? 'none' : 'inline'}>
                  <br></br>
                  <CollapsibleTable trades={trades}/>
                </Box> 
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={shouldOpenModel}
                  onClose={handleCloseAddEditTradeView}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={shouldOpenModel}>
                    <Paper className={classes.paper}>
                      <AddTradeView 
                        editMode={isEditMode} 
                        tradeToEdit={props.tradInFocus} 
                        closeModal={() => handleCloseAddEditTradeView()} 
                        refreshData={ () => getTrades()}/>
                    </Paper>
                  </Fade>
                </Modal>
                <Modal
                  aria-labelledby="transition-modal-title1"
                  aria-describedby="transition-modal-description1"
                  className={classes.modal}
                  open={shouldOpenDeleteConfirmModal}
                  onClose={handleCloseDeleteConfirmView}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={shouldOpenDeleteConfirmModal}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.withPadding} variant='subtitle1' color='textPrimary'>Are you sure?</Typography>
                      <Button className={classes.withPadding} onClick={() => handleCloseDeleteConfirmView()} >Cancel</Button>
                      <Button className={classes.withPadding} onClick={() => deleteTrade()} color="primary" variant="contained">Delete</Button>
                    </Paper>
                  </Fade>
                </Modal>

                
            </div>
          ) 
    }
})

export default TradingLogView