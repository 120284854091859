import React, { useEffect, useContext, useState }  from "react";
import { Typography, Grid, colors} from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import DashboardItemView from "./DashboardItemView";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FlagIcon from '@material-ui/icons/Flag';
import MoneyIcon from '@material-ui/icons/Money';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import LoadingScreen from "./LoadingScreen";
import { AppContext } from '../AppContext';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

interface DashboardViewProps {
    hidden: boolean
}

function DashboardView(props: DashboardViewProps) {
    const theme = useTheme()
    const appContext = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(false)
    const [profitToDate, setProfitToDate] = useState<number>(0)
    const [winRatio, setWinRatio] = useState<number>(0)
    const [avgReturnPerTrade, setAvgReturnPerTrade] = useState<number>(0)
    const [pctReturnOnWins, setPctReturnOnWins] = useState<number>(0)
    const [pctReturnOnLosses, setPctReturnOnLosses] = useState<number>(0)
    const [pctReturnAllTrades, setPctReturnOnAllTrades] = useState<number>(0)
    const [numberOfWinningTrades, setNumberOfWinningTrades] = useState<number>(0)
    const [numberOfLosingTrades, setNumberOfLosingTrades] = useState<number>(0)
    const [numberOfAllTrades, setNumberOfAllTrades] = useState<number>(0)
    const [viewDidLoad, setViewDidLoad] = useState(false)
    const { user, authStatus } = useAuthenticator((context) => [context.user])
    const getAccessJwtToken = async () => {
        // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        console.log('jwtToken:', token);
        return token
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    

    useEffect(() => {
        async function getDashboardData() {
            if (authStatus === 'authenticated') {
              setIsLoading(true)
              try {
                const result = await fetch(`${process.env.REACT_APP_API_DOMAIN}/gettradestats?userId=${user.attributes?.email}`,
                {
                    headers: {
                        'Authorization': `${await getAccessJwtToken()}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await result.clone().json();
                console.log('Result:', data);
                setProfitToDate(data.profitToDate)
                setWinRatio(data.winRatio)
                setAvgReturnPerTrade(data.avgReturnPerTrade)
                setPctReturnOnWins(data.avgPctReturnPerWin)
                setPctReturnOnLosses(data.avgPctReturnPerLoss)
                setPctReturnOnAllTrades(data.avgPctReturnPerTrade)
                setNumberOfWinningTrades(data.numberOfWinningTrades)
                setNumberOfLosingTrades(data.numberOfLosingTrades)
                setNumberOfAllTrades(data.numberOfTrades)
                setIsLoading(false)
              } catch (error) {
                console.error('Error', error);
                appContext.showError('')
                setIsLoading(false)
            }  
            }    
        }
         
        if (!viewDidLoad && authStatus === 'authenticated') {
            setViewDidLoad(true)
            getDashboardData()
        } 

    }, [appContext, user.attributes?.email, authStatus, viewDidLoad])


    if (props.hidden === true) {
        return <div />
    }
    
    if (isLoading) {
        return <LoadingScreen />
    }

    return(
        <div>
        <Typography variant='h4' style={{color:theme.palette.primary.dark}}>Dashboard</Typography>
            <br></br>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item xs>
                     <DashboardItemView title='Profit-to-date' value={formatter.format(profitToDate)} icon={AttachMoneyIcon} iconBgColor={colors.green[500]}/>
                </Grid>
                <Grid item xs>
                    <DashboardItemView title='Win ratio' value={`${(winRatio*100).toFixed(2)}%`} icon={FlagIcon} iconBgColor={colors.green[500]}/>
                </Grid> 
                <Grid item xs>
                    <DashboardItemView title='Avg return per trade' value={formatter.format(avgReturnPerTrade)} icon={MoneyIcon} iconBgColor={colors.green[500]}/>
                </Grid>     
            </Grid>
            <br></br>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item xs>
                    <DashboardItemView title='% Return on wins' value={`${(pctReturnOnWins*100).toFixed(2)}%`} icon={DataUsageIcon} iconBgColor={colors.green[500]}/>
                </Grid> 
                <Grid item xs>
                    <DashboardItemView title='% Return on losses' value={`${(pctReturnOnLosses*100).toFixed(2)}%`} icon={DataUsageIcon} iconBgColor={colors.red[500]}/>
                </Grid>
                <Grid item xs>
                     <DashboardItemView title='% Return all trades' value={`${(pctReturnAllTrades*100).toFixed(2)}%`} icon={DataUsageIcon} iconBgColor={colors.blue[500]}/>
                </Grid>
            </Grid>
            <br></br>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item xs>
                     <DashboardItemView title='Winning trades' value={`${numberOfWinningTrades}`} icon={EqualizerIcon} iconBgColor={colors.green[500]}/>
                </Grid>
                <Grid item xs>
                    <DashboardItemView title='Losing trades' value={`${numberOfLosingTrades}`} icon={EqualizerIcon} iconBgColor={colors.red[500]}/>
                </Grid> 
                <Grid item xs>
                    <DashboardItemView title='Total trades' value={`${numberOfAllTrades}`} icon={EqualizerIcon} iconBgColor={colors.blue[500]}/>
                </Grid>      
            </Grid>         
        </div>
    )
}

export default DashboardView