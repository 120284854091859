import React, { useState, useEffect } from "react";
import { Container, Divider, Grid, Typography, Box, colors } from '@material-ui/core'
import { Theme, makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import LoginButton from "./LoginButton";
import DashboardItemView from "./DashboardItemView";
import FlagIcon from '@material-ui/icons/Flag';
import MoneyIcon from '@material-ui/icons/Money';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import LogoImg from '../assets/logo.png';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 110,
      width: 250,
      padding: theme.spacing(1)
    },
    bottomLbl: {
        marginTop: theme.spacing(5),
    },
    whiteText: {
        color: '#ffffff'
    },
    right: {
      float: 'right',
      marginRight: theme.spacing(1),
    },
    center: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '15%',
    },
    centerMobile: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '40%',
    }
  }),
);

function HomeScreen() {
    const theme = useTheme()
    const classes = useStyles()
    const [winRatio, setWinRatio] = useState<number>(0)
    const [avgReturnPerTrade, setAvgReturnPerTrade] = useState<number>(0)
    const [pctReturnAllTrades, setPctReturnOnAllTrades] = useState<number>(0)
    const [viewDidLoad, setViewDidLoad] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {

      async function getPeformance() {
        try {
          const result = await fetch(`${process.env.REACT_APP_API_DOMAIN}/getplatformperformance`,
          {
              headers: {
                  'Content-Type': 'application/json'
              }
          });
          const data = await result.clone().json();
          console.log('Result:', data);
          setWinRatio(data.winRatio)
          setAvgReturnPerTrade(data.avgReturnPerTrade)
          setPctReturnOnAllTrades(data.avgPctReturnPerTrade)
        } catch (error) {
          console.error('Error', error);
        }      
    }

         
      if (!viewDidLoad) {
          setViewDidLoad(true)
          getPeformance()
      } 

    }, [viewDidLoad])

    

    return (
        <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
          <div style={{ backgroundColor: theme.palette.primary.main }}>
            <br></br>
            <Box className={classes.right}>
              <LoginButton/>
            </Box>
            <br></br> 
            <br></br>
            {isTabletOrMobile && <img src={LogoImg} alt="Okay Investments LLC" className={classes.centerMobile}/>}
            {!isTabletOrMobile && <img src={LogoImg} alt="Okay Investments LLC" className={classes.center}/>}
          </div>
          <div>
          <Container>
            <br></br>
            <br></br>            
            <Typography color="textSecondary" variant='h5' align='center'>Trading options using Machine Learning</Typography>
            <br></br>
            <Typography variant='body1' align='center' color='textPrimary'>Our strategy is to deliver small but repeatable wins on all our trades. We are able to achieve this goal by leveraging intelligent forecasting, strict exit rules and active monitoring.</Typography>
            <br></br>
            <br></br>
            <Divider />
            <br></br>
            <br></br>
            <Typography color="textSecondary" variant='h5' align='center'>Performance since 2020</Typography>
            <br></br>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item xs>
                    <DashboardItemView title='Avg return per trade' value={formatter.format(avgReturnPerTrade)} icon={MoneyIcon} iconBgColor={colors.green[500]}/>
                </Grid>   
                <Grid item xs>
                     <DashboardItemView title='% Return all trades' value={`${(pctReturnAllTrades*100).toFixed(2)}%`} icon={DataUsageIcon} iconBgColor={colors.blue[500]}/>
                </Grid>  
            </Grid>
            <br></br>
            <br></br>
            <Typography variant='h5' align='center' color='textPrimary'>CHICAGO, ILLINOIS</Typography>
            <Typography variant='body2' align='center' color='textPrimary'>All rights reserved. Okay Investments LLC.</Typography>
            <br></br>
            <br></br>
          </Container>
          </div>
        </div>
      
    )
}

export default HomeScreen