import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface RiskMonitoringtemProps {
    trade: Trade
}

const useStyles = makeStyles((theme) => ({
  primaryText: {
    color: theme.palette.primary.dark
  }
}));

function RiskMonitoringItemView(props: RiskMonitoringtemProps) {
  const classes = useStyles();
  function formatDate(date: Date) {
    if (moment(date).isValid()) {
        return moment(date).utc().format('MMMDD')
    } else {
        return '-'
    }
}

  return (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              variant="h5"
            >
              {`${props.trade.symbol} ${(props.trade.expirationDate) ? (formatDate(props.trade.expirationDate)) : ''} (${moment(props.trade.expirationDate).utc().endOf('day').diff(moment().startOf('day'), 'days')}) ${props.trade.strikePriceA}/${props.trade.strikePriceB}`}
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle1"
              gutterBottom
            >
              {`σ: $${Number(props.trade.firstStddev).toFixed(2)} 1.5σ: $${Number(props.trade.midStddev).toFixed(2)} 2σ: $${Number(props.trade.secondStddev).toFixed(2)}`}
            </Typography>
            <br></br>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <div className={classes.primaryText}>Market price<Typography color="textPrimary">{(props.trade.currentMarketPrice) ? formatter.format(props.trade.currentMarketPrice) : '-'}</Typography></div>
              </Grid>
              <Grid item>
                <div className={classes.primaryText}>% Distance
                  <Typography color="textPrimary">
                    {(props.trade.bufferFromCurrentPrice) ? `${Number(props.trade.bufferFromCurrentPrice *100).toFixed(2)}%` : '-'}  
                  </Typography>
                </div>
              </Grid>              
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {(props.trade.currentOTMProb) ? `${Number(props.trade.currentOTMProb *100).toFixed(2)}%` : "-"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>  )
}


 export default RiskMonitoringItemView
