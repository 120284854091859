import React, { useState, useEffect, useContext, forwardRef }  from "react";
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, Box, TextField, MenuItem } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LoadingScreen from "./LoadingScreen";
import moment from 'moment';
import { AppContext } from '../AppContext';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

moment.suppressDeprecationWarnings = true

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    fab: {
      margin: 0,
      top: 'auto',
      right: theme.spacing(3),
      bottom: theme.spacing(3),
      left: 'auto',
      position: 'fixed',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #fff',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: 'none'
    },
    withPadding: {
      margin: theme.spacing(1)
    },
}));

const symbols = [
    {
      value: 'SPY',
      label: 'SPY',
    },
    {
      value: 'DIA',
      label: 'DIA',
    },
    {
      value: 'IWM',
      label: 'IWM',
    },
    {
      value: 'QQQ',
      label: 'QQQ',
    },
];

interface RowProps {
    forecast: Forecast,
    index: number
}

function Row(props: RowProps) {
    const classes = useStyles();
    const theme = useTheme();

    function formatDate(date: Date) {
        if (moment(date).isValid()) {
            return moment(date).utc().format('MMM DD YYYY')
        } else {
            return '-'
        }
    }

  return (
    <React.Fragment>
        <TableRow key='forecastDetailByDate' className={classes.root} style={(props.index%2) ? ({backgroundColor: theme.palette.common.white}) : ({backgroundColor: theme.palette.action.hover})}>
              <TableCell component="th" scope="row">{(props.forecast.date) ? (formatDate(props.forecast.date)) : '-'}</TableCell>
              <TableCell align="right">{Number(props.forecast.target).toFixed(2)}</TableCell>
              <TableCell align="right">{Number(props.forecast.firstSigma).toFixed(2)}</TableCell>
              <TableCell align="right">{Number(props.forecast.secondSigma).toFixed(2)}</TableCell>
        </TableRow>
    </React.Fragment>
  );
}

interface DenseTableTableProps {
    forecasts: Forecast[]
}

function DenseTable(props: DenseTableTableProps) {
  const theme = useTheme()

  return (   
    <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left"><Typography variant='h6' style={{color:theme.palette.primary.dark}}>Date</Typography></TableCell>
            <TableCell align="right">
              <Tooltip title='Forecast will be at'>
                <Typography variant='h6' style={{color:theme.palette.primary.dark}}>Estimate</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="right">
              <Tooltip title='% confidence will be above this value'>
                <Typography variant='h6' style={{color:theme.palette.primary.dark}}>95%</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="right">
              <Tooltip title='% confidence will be above this value'>
                <Typography variant='h6' style={{color:theme.palette.primary.dark}}>99%</Typography>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.forecasts.map((forecast, index) => (
            <Row index={index} key={forecast.date.toString()} forecast={forecast}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface ForecastViewProps {
    hidden: boolean
    symbol: string
}

const ForecastView = forwardRef((props: ForecastViewProps, ref: any) => {
    const [forecasts, setForecasts] = useState([]);
    const [didReturnEmpty, setDidReturnEmpty] = useState(false);
    const appContext = useContext(AppContext);
    const theme = useTheme()
    const { authStatus } = useAuthenticator((context) => [context.user])
    const getAccessJwtToken = async () => {
      // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      return token
    };

    async function getForecast(symbol: string) {           
        if (authStatus === 'authenticated') {
          try {
            const result = await fetch(`${process.env.REACT_APP_API_DOMAIN}/getforecast?symbol=${symbol}`,
                {
                    headers: {
                        'Authorization': `${await getAccessJwtToken()}`,
                        'Content-Type': 'application/json'
                    }
                });
            const data = await result.clone().json();
            console.log('Result:', data);
            setDidReturnEmpty(data.length === 0)
            setForecasts(data.forecasts)
          } catch (error) {
            console.error('Error', error);
            appContext.showError('')
           }  
        }      
    }

    const initialData = () => {
        return props.symbol
      }
    const [symbol, setSymbol] = useState<string | null>(
        initialData
    )

    const handleSymbolChange = (event: any) => {
        setSymbol(event.target.value)
        getForecast(event.target.value || '')
    };

    useEffect(() => {
        if (forecasts.length === 0 && !didReturnEmpty) {
            getForecast(symbol || '')
        }
    })

    if (props.hidden === true) {
        return <div />
    } else {
        if (forecasts.length === 0 && !didReturnEmpty) {
          return <LoadingScreen />
        }
        return (             
            <div> 
                <Typography variant='h4' style={{color:theme.palette.primary.dark}}>AI Forecast</Typography>
                <br></br>
                <TextField value={symbol || ''} select name="symbol" label="Symbol" variant="outlined" defaultValue='SPY' onChange={ (e) => handleSymbolChange(e)}>
                    {symbols.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <br></br>
                <Box display={ didReturnEmpty ? 'none' : 'inline'}>
                  <br></br>
                  <DenseTable forecasts={forecasts}/>
                </Box>  
            </div>
          ) 
    }
})

export default ForecastView