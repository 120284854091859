import 'date-fns';
import React, { useState, useContext } from "react";
import { TextField, Typography, Button, Box, MenuItem, InputAdornment, CircularProgress } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { AppContext } from '../AppContext';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    alignRight: {
        float:'right',
    },
    withPadding: {
        margin: theme.spacing(1)
    },
  }),
);

const symbols = [
    {
      value: 'SPY',
      label: 'SPY',
    },
    {
      value: 'DIA',
      label: 'DIA',
    },
    {
      value: 'IWM',
      label: 'IWM',
    },
    {
      value: 'QQQ',
      label: 'QQQ',
    },
];

const statuses = [
    {
      value: 'NEW',
      label: 'NEW',
    },
    {
      value: 'OPEN',
      label: 'OPEN',
    },
    {
      value: 'EXPIRED',
      label: 'EXPIRED',
    },
    {
      value: 'CLOSED',
      label: 'CLOSED',
    },
];

interface AddTradeProps {
    closeModal: () => void,
    refreshData: () => void,
    editMode: boolean,
    tradeToEdit: Trade | undefined
}

function AddTradeView(props: AddTradeProps) {
    const classes = useStyles();
    let future = new Date()
    const appContext = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false)
    const [isImpliedVolatiltyError, setIsImpliedVolatiltyError] = useState(false)
    const [isUnderlyingPriceError, setIsUnderlyingPriceError] = useState(false)
    const [isUnderlyingClosingPriceError, setIsUnderlyingClosingPriceError] = useState(false)
    const [isPriceError, setIsPriceError] = useState(false)
    const [isExposureError, setIsExposureError] = useState(false)
    const [isStrikeAError, setIsStrikeAError] = useState(false)
    const [isStrikeBError, setIsStrikeBError] = useState(false)
    const [isQuantityError, setIsQuantityError] = useState(false)
    const [isTransactionDateError, setIsTransactionDateError] = useState(false)
    const [isExpirationDateError, setIsExpirationDateError] = useState(false)
    const [isClosingDateError, setIsClosingDateError] = useState(false)
    const { user, authStatus } = useAuthenticator((context) => [context.user])
    const getAccessJwtToken = async () => {
        // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        return token
    };

    const initialTradeData = () => {
        if (props.editMode && props.tradeToEdit) {
            return props.tradeToEdit
        } else {
            return ({
                symbol: 'SPY',
                status: 'NEW',
                expirationDate: new Date(future.setDate(future.getDate() + 30)),
                transactionDate: new Date()
            })
        }
    }
    const [trade, setTrade] = useState<Trade | null>(
        initialTradeData
    )

    async function submitTrade() {
        if (authStatus === 'authenticated') {
            setIsLoading(true)
            const body = {
                "symbol": trade?.symbol,
                "strikePriceA": trade?.strikePriceA as number,
                "strikePriceB": trade?.strikePriceB as number,
                "expirationDate": (trade?.expirationDate) ? moment(trade?.expirationDate).utc().format('MM/DD/YYYY') : undefined,
                "quantity": trade?.quantity as number,
                "price": trade?.price as number,
                "impliedVolatility": trade?.impliedVolatility as number,
                "transactionDate": (trade?.transactionDate) ? moment(trade?.transactionDate).utc().format('MM/DD/YYYY') : undefined,
                "exposure": trade?.exposure as number,
                "underlyingClosingPrice": trade?.underlyingClosingPrice as number,
                "underlyingPrice": trade?.underlyingPrice as number,
                "closingDate": (trade?.closingDate) ? moment(trade?.closingDate).utc().format('MM/DD/YYYY') : undefined,
                "status": trade?.status,
                "userId": user.attributes?.email
            }
            console.log(body)
            let url = props.editMode ? `${process.env.REACT_APP_API_DOMAIN}/updatetrade?tradeId=${trade?.id}` : `${process.env.REACT_APP_API_DOMAIN}/createtrade`
            let method = props.editMode ? 'PUT' : 'POST'
            try {
                const result = await fetch(url,
                    {
                        method: method,
                        cache: 'no-cache',
                        headers: {
                            'Authorization': `${await getAccessJwtToken()}`,
                            'Content-Type': 'application/json'
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify(body)
                    });
                const resp = result.clone()
                if (resp.status === 200 || resp.status === 201) {
                    setIsLoading(false)
                    props.refreshData()
                    props.closeModal()
                } else {
                    appContext.showError('')
                }
            } catch (error) {
                console.error('Error', error);
                props.closeModal()
                setIsLoading(false)
                appContext.showError('')
            }
        }
    }

    const handleTradeDataChange = (event: any) => {
        const name = event.target.name
        const value = event.target.value

        switch (name) {
            case 'symbol':
                setTrade({...trade, ...{symbol: value}})
            break;
            case 'status':
                if (value === 'EXPIRED' || value === 'CLOSED') {
                    if (!trade?.closingDate) {
                        setTrade({...trade, ...{closingDate: new Date(), status: value}})
                    } else {
                        setTrade({...trade, ...{status: value}})
                    }   
                } else {
                    setTrade({...trade, ...{closingDate: undefined, status: value}})
                }
            break;
            case 'impliedVolatility':
                setIsImpliedVolatiltyError(!(Number(value)))
                setTrade({...trade, ...{impliedVolatility: value}})
            break;
            case 'underlyingPrice':
                setIsUnderlyingPriceError(!(Number(value)))
                setTrade({...trade, ...{underlyingPrice: value}})
            break;
            case 'underlyingClosingPrice':
                setIsUnderlyingClosingPriceError(!(Number(value)))
                setTrade({...trade, ...{underlyingClosingPrice: value}})
            break;
            case 'price':
                setIsPriceError(!(Number(value)))
                setTrade({...trade, ...{price: value}})
            break;
            case 'quantity':
                setIsQuantityError(!(Number(value)))
                setTrade({...trade, ...{quantity: value}})
            break;
            case 'exposure':
                setIsExposureError(!(Number(value)))
                setTrade({...trade, ...{exposure: value}})
            break;
            case 'strikeA':
                setIsStrikeAError(!(Number(value)))
                setTrade({...trade, ...{strikePriceA: value}})
            break;
            case 'strikeB':
                setIsStrikeBError(!(Number(value)))
                setTrade({...trade, ...{strikePriceB: value}})
            break;
        }
    }

    const shouldEnableSave = () => {
        return trade?.symbol && 
        trade?.impliedVolatility &&
        trade?.underlyingPrice &&
        trade?.price &&
        trade?.quantity &&
        trade?.exposure &&
        trade?.strikePriceA &&
        trade?.strikePriceB &&
        trade?.transactionDate &&
        trade?.expirationDate &&
        !isPriceError &&
        !isImpliedVolatiltyError &&
        !isUnderlyingPriceError &&
        !isExposureError &&
        !isStrikeAError &&
        !isStrikeBError &&
        !isTransactionDateError &&
        !isExpirationDateError &&
        !isQuantityError &&
        !isClosingDateError &&
        !isUnderlyingClosingPriceError
    }

    const handleTransactionDateChange = (date: Date | null | undefined) => {
        setIsTransactionDateError(!moment(date).isValid())
        setTrade({...trade, ...{transactionDate: date}})
    };

    const handleExpirationDateChange = (date: Date | null | undefined) => {
        setIsExpirationDateError(!moment(date).isValid())
        setTrade({...trade, ...{expirationDate: date}})
    };
    
    const handleClosingDateChange = (date: Date | null | undefined) => {
        setIsClosingDateError(!moment(date).isValid())
        setTrade({...trade, ...{closingDate: date}})
    }; 

    return(
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form className={classes.root} noValidate autoComplete="off">
                <Typography variant='h6' color='textPrimary'>Underlying info</Typography>
                <div>
                    <TextField disabled={props.editMode} value={trade?.symbol || ''} required select name="symbol" label="Symbol" variant="outlined" defaultValue='SPY' onChange={ (e) => handleTradeDataChange(e)}>
                        {symbols.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField value={trade?.impliedVolatility || ''} type='number' error={isImpliedVolatiltyError} required name="impliedVolatility" label="Implied volatility" variant="outlined" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} onChange={ (e) => handleTradeDataChange(e)}/>
                    <TextField value={trade?.underlyingPrice || ''} type='number' error={isUnderlyingPriceError} required name="underlyingPrice" label="Underlying price" variant="outlined" helperText="Current market price" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,}} onChange={ (e) => handleTradeDataChange(e)}/>
                </div>
                <Typography variant='h6' color='textPrimary'>Trade info</Typography>
                <div>
                    <TextField value={trade?.price || ''} type='number' error={isPriceError} required name="price" label="Price" variant="outlined" helperText="Spread or premium" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,}} onChange={ (e) => handleTradeDataChange(e)}/>
                    <TextField value={trade?.quantity || ''} type='number' error={isQuantityError} required name="quantity" label="Quantity" variant="outlined" onChange={ (e) => handleTradeDataChange(e)}/>
                    <TextField value={trade?.exposure || ''} type='number' error={isExposureError} required name="exposure" label="Exposure" variant="outlined" helperText="Max potential loss" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,}} onChange={ (e) => handleTradeDataChange(e)}/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        name="transactionDate"
                        label="Transaction date"
                        value={(trade?.transactionDate) ? moment(trade?.transactionDate).utc().format('MM/DD/YYYY') : ''}
                        onChange={handleTransactionDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </div>
                <div>
                <Box display={!props.editMode ? 'none' : 'inline'}>
                    <Box display={(trade?.status === 'CLOSED' || trade?.status === 'EXPIRED') ? 'inline' : 'none'}>
                    <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            margin="normal"
                            name="closingDate"
                            label="Closing date"
                            value={(trade?.closingDate) ? moment(trade?.closingDate).utc().format('MM/DD/YYYY') : new Date()}
                            onChange={handleClosingDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    <TextField value={trade?.underlyingClosingPrice || ''} type='number' error={isPriceError} name="underlyingClosingPrice" label="Underlying closing price" variant="outlined" helperText="Exit or closing price" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,}} onChange={ (e) => handleTradeDataChange(e)}/>    
                    </Box>                    
                    <TextField value={trade?.status || ''} required select name="status" label="Status" variant="outlined" defaultValue='NEW' onChange={ (e) => handleTradeDataChange(e)}>
                        {statuses.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <TextField name="strategy" label="Strategy" defaultValue="Bull put spread" disabled variant="outlined" />
                </div>
                <Typography variant='h6' color='textPrimary'>Options details</Typography>
                <div>
                    <TextField value={trade?.strikePriceA || ''} type='number' error={isStrikeAError} required name="strikeA" label="Strike A" variant="outlined" helperText="Upper strike price" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,}} onChange={ (e) => handleTradeDataChange(e)}/>
                    <TextField value={trade?.strikePriceB || ''} type='number' error={isStrikeBError} required name="strikeB" label="Strike B" variant="outlined" helperText="Lower strike price" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,}} onChange={ (e) => handleTradeDataChange(e)}/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        name="expirationDate"
                        label="Expiration date"
                        value={(trade?.expirationDate) ? moment(trade?.expirationDate).utc().format('MM/DD/YYYY') : ''}
                        onChange={handleExpirationDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </div>             
            </form>
            <Box className={classes.alignRight} display={isLoading ? 'none' : 'inline'}>
                <Button className={classes.withPadding} onClick={() => props.closeModal()}>Cancel</Button>
                <Button className={classes.withPadding} variant="contained" color="primary" onClick={ () => submitTrade()} disabled={!shouldEnableSave()}>Save</Button>              
            </Box>
            <Box className={classes.alignRight} display={!isLoading ? 'none' : 'inline'}>
                <CircularProgress />                
            </Box>           
            </MuiPickersUtilsProvider>            
        </div>    )
}

export default AddTradeView