import React from "react";
import loading from "../assets/loading.svg";
import { Box } from '@material-ui/core'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padded: {
        padding: theme.spacing(5)
    }
  }),
);

function LoadingScreen() {
    const classes = useStyles()

    return(
        <Box className={classes.padded} display="flex" alignItems="center" justifyContent="center">
        <div className="spinner">
            <img src={loading} alt="Loading" />
        </div>
    </Box>
    )
  
}

export default LoadingScreen;